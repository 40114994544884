import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Action, SwedishBankID_CollectModel } from '../renditions';
import Frame from '../components/Frame';
import Footer from '../components/Footer';

import './SwedishBankID.css';
import { Language } from '@criipto/verify-react';
import { LocalizedTexts } from '../components/Language';
import ActionHeader from '../components/ActionHeader';

export default function Collect(props: {
  model: SwedishBankID_CollectModel,
  action: Action,
  userAgent: string,
  allowAutoLaunch: boolean
}) {
  const {model, action, allowAutoLaunch} = props;
  const [error, setError] = useState<null | string>(null);
  const [pending, setPending] = useState(false);

  const launchUrl = useMemo(() => {
    let redirectValue = 'null';
    if (isWindowsPhone(props.userAgent) || isiOSSafari(props.userAgent)) {
      redirectValue = window.encodeURIComponent(window.location.href);
    }
    return `${model.launchUrl}&redirect=${redirectValue}`;
  }, [props.userAgent]);

  useEffect(() => {
    if (error) document.body.classList.add('has-error');
    else document.body.classList.remove('has-error');

    if (pending) document.body.classList.add('is-pending');
    else document.body.classList.remove('is-pending');

    if (isAndroid(props.userAgent)) document.body.classList.add('is-android');
    else document.body.classList.remove('is-android');
  }, [error, pending, props.userAgent]);

  useEffect(() => {
    if (isAndroid(props.userAgent) || !allowAutoLaunch) return;
    setTimeout(() => {
      window.location.href = launchUrl;
    }, 0);
  }, [props.userAgent, launchUrl, allowAutoLaunch]);

  const poll = useCallback(async () => {
    const response = await fetch(model.pollUrl);
    if (response.status >= 400) {
      setError(await response.clone().text());
    } else if (response.status === 202) {
      return response.status;
    } else {
      const payload : {targetUrl: string} = await response.json();
      window.location.href = payload.targetUrl;
    }
    return response.status;
  }, [model.pollUrl]);

  useEffect(() => {
    if (!shouldPoll(props.userAgent)) return;
    let subscribed = true;

    const recursivePoll = async (delay: number) => {
      if (!subscribed) return;
      try {
        const status = await poll();
        if (status === 202) setTimeout(() => recursivePoll(delay), delay);
      } catch (err) {
        setTimeout(() => recursivePoll(delay), delay);
      }
    }
    
    recursivePoll(5000);

    return () => {
      subscribed = false;
    }
  }, [model.pollUrl, props.userAgent]);

  const handleLaunch = (event: React.MouseEvent) => {
    setPending(true);
  }

  return (
    <React.Fragment>
      <ActionHeader
        action={action}
        language={model.language}
        suffix="BankID"
      />
        {/** Error frame */}
        <Frame className="centered sebankid-collect default-hidden has-error-show" id="sebankid_error_frame">
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Sorry, but something went wrong. Please try again in a few minutes.`},
                {language: 'sv', text: `Förlåt, men något gick fel. Försök gärna igen om en liten stund.`}
              ]
            }
          />
          <p id="error" className="error">{error}</p>
        </Frame>
        {/** Start app frame */}
        <Frame className="sebankid-this-device centered text-center has-error-hide" id="sebank_thisdevice_collect_frame">
          <div className="default-hidden is-android-show">
            <div className="is-pending-hide">
                <p id="sebank_thisdevice_collect_intro">
                  <LocalizedTexts
                    texts={
                      [
                        {language: 'en', text: `Open page in "BankID"`},
                        {language: 'sv', text: `Öppna sidan i "BankID"?`}
                      ]
                    }
                  />
                </p>
                <a id="launchLink" type="button" className="button button-primary" href={launchUrl} onClick={handleLaunch}>
                  <LocalizedTexts
                    texts={
                      [
                        {language: 'en', text: `Open`},
                        {language: 'sv', text: `Öppna`}
                      ]
                    }
                  />
                </a>
            </div>
            <div className="default-hidden is-pending-show">
              <div className="spinner"></div>
            </div>
          </div>
          <div className="is-android-hide">
            <div className="default-hidden" id="sebankid_startapp_message_before"></div>
            <p id="sebankid_startapp_message">
              <LocalizedTexts
                texts={
                  [
                    {language: 'en', text: `Starting the BankID application`},
                    {language: 'sv', text: `Startar BankID-applikationen`}
                  ]
                }
              />
            </p>
            <div className="default-hidden" id="sebankid_startapp_message_after"></div>
          </div>
        </Frame>
      <Footer language={model.language as Language} />
    </React.Fragment>
  );
}

// Legacy javascript, TODO: Refactor
const testUA = function (pattern: RegExp, userAgent: string) {
  return pattern.test(userAgent);
};
const isiOS = function (userAgent: string) {
  return testUA(/iPad|iPhone|iPod/, userAgent) && !("MSStream" in window);
};
const isWindowsPhone = function (userAgent: string) { return testUA(/Windows Phone/i, userAgent); };
const isiOSSafari = function (userAgent: string) {
  return isiOS(userAgent) && !testUA(/ CriOS\/[.0-9]*/, userAgent);
};
const isAndroid = function (userAgent: string) { return testUA(/Android/, userAgent); };
const shouldPoll = function (userAgent: string) {
  if (isiOSSafari(userAgent)) return false;

  return true;
};