import React from 'react';
import { Action, DanishMitID_Company, DanishMitID_CvrSelectModel } from '../renditions';
import Frame from '../components/Frame';
import { LocalizedTexts } from '../components/Language';
import HiddenFormFields from '../components/HiddenFormFields';
import { Language } from '@criipto/verify-react';

import './DanishMitID.css';
import './CvrSelect.css';
import DanishMitIDHeader, { actionTexts } from './Header';
import DanishMitIDFooter from './Footer';

export default function CvrSelect(props: {
  model: DanishMitID_CvrSelectModel,
  action: Action
}) {
  const {model, action} = props;
  const actionText = 
    (
      actionTexts(action).find(s => s.language === model.Language) ??
      actionTexts(action).find(s => s.language === 'da')!
    ).text;

  return (
    <React.Fragment>
      <DanishMitIDHeader
        action={action}
        language={model.Language}
      />
      <Frame className="dkmitid-cvr-select">
        <div className="mitid-border">
          <form method="POST" action={model.formAction}>
            <HiddenFormFields fields={model.OriginalRequestParameters} />

            <div className="dkmitid-cvr-select-groups">
              {model.AllowIndividual ? (
                <div>
                    <p id="dkmitid_cvrselect_intro_individual">
                    <LocalizedTexts
                      texts={
                        [
                          {language: 'en', text: `${actionText} as a private individual`},
                          {language: 'da', text: `${actionText} som privatperson`}  
                        ]
                      }
                    />
                    </p>
                    <button type="submit" name="individual" value="true" className="button button-default" id="dkmitid_cvrselect_individual_submit">
                      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.875 11.875H7.125C3.29688 11.875 0.25 14.9609 0.25 18.75C0.25 19.4531 0.796875 20 1.5 20H16.5C17.1641 20 17.75 19.4531 17.75 18.75C17.75 14.9609 14.6641 11.875 10.875 11.875ZM2.125 18.125C2.4375 15.6641 4.54688 13.75 7.125 13.75H10.875C13.4141 13.75 15.5234 15.6641 15.8359 18.125H2.125ZM9 10C11.7344 10 14 7.77344 14 5C14 2.26562 11.7344 0 9 0C6.22656 0 4 2.26562 4 5C4 7.77344 6.22656 10 9 10ZM9 1.875C10.7188 1.875 12.125 3.28125 12.125 5C12.125 6.75781 10.7188 8.125 9 8.125C7.24219 8.125 5.875 6.75781 5.875 5C5.875 3.28125 7.24219 1.875 9 1.875Z" />
                      </svg>
                      <div className="text">
                        {model.ButtonTextIndividual}
                      </div>
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5625 0.773438L17.4375 7.33594C17.6328 7.53125 17.75 7.76562 17.75 8.03906C17.75 8.27344 17.6328 8.50781 17.4375 8.70312L10.5625 15.2656C10.2109 15.6172 9.58594 15.6172 9.23438 15.2266C8.88281 14.875 8.88281 14.25 9.27344 13.8984L14.4688 8.97656H1.1875C0.640625 8.97656 0.25 8.54688 0.25 8.03906C0.25 7.49219 0.640625 7.10156 1.1875 7.10156H14.4688L9.27344 2.14062C8.88281 1.78906 8.88281 1.16406 9.23438 0.8125C9.58594 0.421875 10.1719 0.421875 10.5625 0.773438Z" />
                      </svg>
                    </button>
                </div>
              ) : null}

              {model.CvrSources.SignatoryOf.length > 0 ? (
                <div>
                  <p id="dkmitid_cvrselect_intro">
                    <LocalizedTexts
                      texts={
                        [
                          {language: 'en', text: `${actionText} as management of`},
                          {language: 'da', text: `${actionText} som ledelsesrepræsentant`}
                        ]
                      }
                    />
                  </p>

                  <ul className="company-list" id="dkmitid_cvrselect_companylist">
                    {model.CvrSources.SignatoryOf.map(company => (
                      <Company key={company.CvrUuid} mode="company" company={company} />
                    ))}
                  </ul>
                </div>
              ) : null}

              {model.CvrSources.EmployedIn.length > 0 ? (
                <div>
                  <p id="dkmitid_cvrselect_intro_employee">
                    <LocalizedTexts
                      texts={
                        [
                          {language: 'en', text: `${actionText} as professional user in`},
                          {language: 'da', text: `${actionText} som erhvervsbruger`}
                        ]
                      }
                    />
                  </p>

                  <ul className="company-list" id="dkmitid_cvrselect_companylist_employee">
                    {model.CvrSources.EmployedIn.map(company => (
                      <Company key={company.CvrUuid} mode="employee" company={company} />
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </Frame>
      <DanishMitIDFooter language={model.Language as Language} />
    </React.Fragment>
  );
}

function Company(props: {
  mode: 'company' | 'employee'
  company: DanishMitID_Company
}) {
  const {company, mode} = props;
  return (
    <li key={company.CvrUuid}>
      <button type="submit" name="cvruuid" value={company.CvrUuid} className="button button-default" id={`dkmitid_cvrselect_${mode}_${company.Cvr}`}>
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5 3.75H15V1.875C15 0.859375 14.1406 0 13.125 0H6.875C5.82031 0 5 0.859375 5 1.875V3.75H2.5C1.09375 3.75 0 4.88281 0 6.25V16.25C0 17.6562 1.09375 18.75 2.5 18.75H17.5C18.8672 18.75 20 17.6562 20 16.25V6.25C20 4.88281 18.8672 3.75 17.5 3.75ZM6.25 1.875C6.25 1.5625 6.52344 1.25 6.875 1.25H13.125C13.4375 1.25 13.75 1.5625 13.75 1.875V3.75H6.25V1.875ZM18.75 16.25C18.75 16.9531 18.1641 17.5 17.5 17.5H2.5C1.79688 17.5 1.25 16.9531 1.25 16.25V6.25C1.25 5.58594 1.79688 5 2.5 5H17.5C18.1641 5 18.75 5.58594 18.75 6.25V16.25Z" />
        </svg>
        <div className="text">
          {company.Name}<br />
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: 'CVR-number:'},
                {language: 'da', text: 'CVR-nummer:'}  
              ]
            }
          />
          <span className="cvr-number">{company.Cvr}</span>
        </div>
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.5625 0.773438L17.4375 7.33594C17.6328 7.53125 17.75 7.76562 17.75 8.03906C17.75 8.27344 17.6328 8.50781 17.4375 8.70312L10.5625 15.2656C10.2109 15.6172 9.58594 15.6172 9.23438 15.2266C8.88281 14.875 8.88281 14.25 9.27344 13.8984L14.4688 8.97656H1.1875C0.640625 8.97656 0.25 8.54688 0.25 8.03906C0.25 7.49219 0.640625 7.10156 1.1875 7.10156H14.4688L9.27344 2.14062C8.88281 1.78906 8.88281 1.16406 9.23438 0.8125C9.58594 0.421875 10.1719 0.421875 10.5625 0.773438Z" />
        </svg>
      </button>
    </li>
  )
}