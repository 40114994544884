import React, { useState } from 'react';
import { LocalizedTexts } from '../../components/Language';
export default function SSNInput(props: {
  language: string
}) {
  const [invalid, setInvalid] = useState(false);
  return (
    <React.Fragment>
      <input
        id="sebankid_anotherdevice_ssn_input"
        placeholder={props.language === 'sv' ? 'ÅÅÅÅMMDDXXXX' : 'YYYYMMDDXXXX'}
        type="tel" 
        name="ssn"
        className={`input-text ${invalid ? 'invalid' : ''}`}
        required
        minLength={12}
        maxLength={12}
        onInvalid={() => setInvalid(true)}
        onInput={() => setInvalid(false)}
      />
      <span className="default-hidden input-validation-error">
        <LocalizedTexts
          texts={
            [
              {language: 'en', text: `Enter SSN with century digits`},
              {language: 'sv', text: `Ange personnummer med sekelsiffror`}
            ]
          }
        />
      </span>
    </React.Fragment>
  )
}