import React, { useMemo } from 'react';
import { parseAuthorizeOptionsFromUrl } from '@criipto/oidc';
import { CriiptoVerifyProvider } from '@criipto/verify-react';
import { ClientConfiguration } from './renditions';

type Props = {
  children: React.ReactNode
  requestUrl: string
  domain: string
  clientConfiguration: ClientConfiguration
}
export default function CriiptoVerifyProviderHelper(props: Props) {
  const {domain, clientConfiguration} = props;
  const requestUrl = useMemo(() => new URL(props.requestUrl!), [props.requestUrl]);
  const params = parseAuthorizeOptionsFromUrl(props.requestUrl!);
  const pkce = params.code_challenge && params.code_challenge_method ? {
    code_challenge: params.code_challenge,
    code_challenge_method: params.code_challenge_method
  } : undefined;

  return (
    <CriiptoVerifyProvider
      domain={domain}
      clientID={clientConfiguration?.client_id!}
      pkce={pkce}
      response={params.response_type === 'code' ? 'code' : 'token'}
      state={params.state}
      nonce={params.nonce}
      protocol={props.requestUrl?.startsWith('http://') ? 'http' : undefined}
      prompt={params.prompt as any}
      loginHint={params.login_hint}
      redirectUri={params.redirect_uri}
      criiptoSdk={requestUrl.searchParams.get('criipto_sdk') || null}
    >
      {props.children}
    </CriiptoVerifyProvider>
  );
}