import React, {useState} from 'react';
import { Action, SwedishBankID_AnotherDeviceSSNModel } from '../renditions';
import Frame from '../components/Frame';
import Footer from '../components/Footer';

import './SwedishBankID.css';
import { Language } from '@criipto/verify-react';
import { LocalizedTexts } from '../components/Language';
import ActionHeader from '../components/ActionHeader';
import HiddenFormFields from '../components/HiddenFormFields';
import SSNInput from './components/SSNInput';

export default function AnotherDeviceSSN(props: {
  model: SwedishBankID_AnotherDeviceSSNModel,
  action: Action,
}) {
  const {model, action} = props;
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    if (submitted) {
      event.preventDefault();
      return;
    }
    setSubmitted(true);
  };

  return (
    <React.Fragment>
      <ActionHeader
        action={action}
        language={model.language}
        suffix="BankID"
      />
      {/** Error frame */}
      <Frame className="centered sebankid-another-device default-hidden has-error-show" id="sebankid_error_frame">
        <LocalizedTexts
          texts={
            [
              {language: 'en', text: `Sorry, but something went wrong. Please try again in a few minutes.`},
              {language: 'sv', text: `Förlåt, men något gick fel. Försök gärna igen om en liten stund.`}
            ]
          }
        />
        <p id="error" className="error"></p>
      </Frame>
      {/** SSN input frame */}
      <Frame className="centered sebankid-another-device has-error-hide">
        <form method="POST" action={`/SEBankIdAsync/${model.formAction}`} onSubmit={handleSubmit}>
          <HiddenFormFields fields={model.formParameters} />
          <div className="form-group" id="sebankid_anotherdevice_ssn_formgroup">
            <label htmlFor="sebankid_anotherdevice_ssn_input" id="sebankid_anotherdevice_ssn_label">
              <LocalizedTexts
                texts={
                  [
                    {language: 'en', text: `Enter your SSN:`},
                    {language: 'sv', text: `Ange ditt personnummer:`}
                  ]
                }
              />
            </label>
            <SSNInput language={model.language} />
          </div>
          <button type="submit" className="button button-primary" id="sebankid_anotherdevice_submit" disabled={submitted}>
            <LocalizedTexts
              texts={
                [
                  {language: 'en', text: `Continue`},
                  {language: 'sv', text: `Fortsätt`}
                ]
              }
            />
          </button>
        </form>
      </Frame>
      <Footer language={model.language as Language} />
    </React.Fragment>
  );
}
